import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
} from '@backstage/core-plugin-api';
import {
  RadarEntry,
  RadarQuadrant,
  RadarRing,
  TechRadarApi,
  techRadarApiRef,
  TechRadarLoaderResponse
} from '@backstage/plugin-tech-radar';

export class TechRadarClient implements TechRadarApi {
  async load(): Promise<TechRadarLoaderResponse> { 
    // const data = await fetch('').then(res => res.json());
    const rings = new Array<RadarRing>();
    rings.push({ id: 'use', name: 'USE', color: '#087f23' });
    rings.push({ id: 'trial', name: 'TRIAL', color: '#00bfa5' });
    rings.push({ id: 'assess', name: 'ASSESS', color: '#ffd600' });
    rings.push({ id: 'hold', name: 'HOLD', color: '#b71c1c' });

    const quadrants = new Array<RadarQuadrant>();
    quadrants.push({ id: 'infrastructure', name: 'Infrastructure' });
    quadrants.push({ id: 'frameworks', name: 'Frameworks' });
    quadrants.push({ id: 'languages', name: 'Languages' });
    quadrants.push({ id: 'processes', name: 'Processes' });

    const entries = new Array<RadarEntry>();

    //
    // LANGUAGES
    //

    entries.push({
      id: 'c#',
      key: 'c#',
      title: 'C#',
      description: 'C# (pronounced "See Sharp") is a modern, object-oriented, and type-safe programming language.',
      quadrant: 'languages',
      url: 'https://docs.microsoft.com/en-us/dotnet/csharp/',
      timeline: [
        {
          ringId: 'use',
          date: new Date('2020-01-01'),
          description: '',
        },
      ],
    });
    entries.push({
      id: 'typescript',
      key: 'typescript',
      title: 'Typescript',
      description: 'TypeScript is JavaScript with syntax for types.',
      quadrant: 'languages',
      url: 'https://www.typescriptlang.org/',
      timeline: [
        {
          ringId: 'use',
          date: new Date('2020-01-01'),
          description: '',
        },
      ],
    });
    entries.push({
      id: 'python3',
      key: 'python3',
      title: 'Python 3',
      description: 'Python is a programming language that lets you work more quickly and integrate your systems more effectively.',
      quadrant: 'languages',
      url: 'https://www.python.org/',
      timeline: [
        {
          ringId: 'trial',
          date: new Date('2020-01-01'),
          description: '',
        },
      ],
    });
    entries.push({
      id: 'golang',
      key: 'golang',
      title: 'Golang',
      description: '',
      quadrant: 'languages',
      url: 'https://go.dev/',
      timeline: [
        {
          moved: +1,
          ringId: 'use',
          date: new Date('2023-01-01'),
          description: '',
        },
        {
          ringId: 'trial',
          date: new Date('2022-10-28'),
          description: '',
        },
      ],
    });



    //
    // INFRASTRUCTURE
    //
    entries.push({
      id: 'mssql',
      key: 'mssql',
      title: 'MsSQL',
      description: '',
      quadrant: 'infrastructure',
      url: '#',
      timeline: [
        {
          moved: -1,
          ringId: 'hold',
          date: new Date('2023-06-01'),
          description: 'Migration to PostgreSql in progress',
        },
        {
          ringId: 'use',
          date: new Date('2022-01-01'),
          description: 'Primary relational database',
        }
      ],
    });
    entries.push({
      id: 'cosmosdb',
      key: 'cosmosdb',
      title: 'CosmosDB',
      description: '',
      quadrant: 'infrastructure',
      url: '#',
      timeline: [
        {
          moved: -1,
          ringId: 'hold',
          date: new Date('2023-06-01'),
          description: 'CosmosDB deprecated in favour of PostgreSql',
        },
        {
          ringId: 'use',
          date: new Date('2020-01-01'),
          description: 'CosmosDB still the primary DB but soon to begin deprecation in favour of Neo4J',
        },
      ],
    });
    entries.push({
      id: 'neo4j',
      key: 'neo4j',
      title: 'Neo4j',
      description: '',
      quadrant: 'infrastructure',
      url: 'https://neo4j.com/',
      timeline: [
        {
          moved: -1,
          ringId: 'hold',
          date: new Date('2022-10-28'),
          description: 'Modelling team is currently using Neo4J (open source license) in new backend services.',
        },
        {
          moved: +1,
          ringId: 'trial',
          date: new Date('2022-01-01'),
          description: 'Modelling team is currently using Neo4J (open source license) in new backend services.',
        },
      ],
    });

    entries.push({
      id: 'postgres',
      key: 'postgres',
      title: 'PostgreSQL',
      description: '',
      quadrant: 'infrastructure',
      url: 'https://www.postgresql.org/',
      timeline: [
        {
          moved: +1,
          ringId: 'use',
          date: new Date('2023-06-01'),
          description: 'PostgreSql is now the primary relational database',
        },
        {
          moved: +1,
          ringId: 'trial',
          date: new Date('2022-10-28'),
          description: 'Trial for use with graph model as database for model data',
        },
        {
          moved: +1,
          ringId: 'assess',
          date: new Date('2022-02-01'),
          description: 'Internal devops only at the moment: testing for use with Backstage.',
        },
      ],
    });

    entries.push({
      id: 'azureblobstore',
      key: 'azureblobstore',
      title: 'Azure Blob Store',
      description: 'Massively scalable and secure object storage for cloud-native workloads, archives, data lakes, high performance computing, and machine learning',
      quadrant: 'infrastructure',
      url: 'https://azure.microsoft.com/en-us/services/storage/blobs/',
      timeline: [
        {
          ringId: 'use',
          date: new Date('2022-01-01'),
          description: '',
        },
      ],
    });


    entries.push({
      id: 'azureappcontainer',
      key: 'azureappcontainer',
      title: 'Azure App Container',
      description: 'Build and deploy modern apps and microservices using serverless containers',
      quadrant: 'infrastructure',
      url: 'https://azure.microsoft.com/en-us/services/container-apps/',
      timeline: [
        {
          moved: +1,
          ringId: 'use',
          date: new Date('2023-06-01'),
          description: 'Production deployment option',
        },
        {
          moved: +1,
          ringId: 'trial',
          date: new Date('2022-01-01'),
          description: 'Currently testing using App Containers together with Neo4J in modelling team',
        },
      ],
    });
    entries.push({
      id: 'azurefunctions',
      key: 'azurefunctions',
      title: 'Azure Functions',
      description: 'Execute event-driven serverless code with an end-to-end development experience',
      quadrant: 'infrastructure',
      url: 'https://azure.microsoft.com/en-us/services/functions/',
      timeline: [
        {
          ringId: 'hold',
          date: new Date('2022-01-01'),
          description: 'No services using Azure Functions at the moment',
        },
        {
          ringId: 'use',
          date: new Date('2022-01-01'),
          description: 'Currently used by the majority of production backend services',
        },
      ],
    });





    //
    // FRAMEWORKS
    //

    entries.push({
      id: 'angular',
      key: 'angular',
      title: 'Angular',
      description: 'The modern web developer\'s platform',
      quadrant: 'frameworks',
      url: 'https://angular.io/',
      timeline: [
        {
          ringId: 'use',
          date: new Date('2020-01-01'),
          description: 'All front-end code is built in Angular',
        },
      ],
    });
    entries.push({
      id: 'gorm',
      key: 'gorm',
      title: 'GORM',
      description: 'ORM library for Golang',
      quadrant: 'frameworks',
      url: 'https://gorm.io/',
      timeline: [
        {
          ringId: 'use',
          date: new Date('2022-10-28'),
          description: 'Use for comminication with PostgreSql datbase',
        },
      ],
    });




    //
    // PROCESSES
    //
    entries.push({
      id: 'kanban',
      key: 'kanban',
      title: 'Kanban',
      description: 'A more flexible version of Scrum',
      quadrant: 'processes',
      url: 'https://en.wikipedia.org/wiki/Kanban_(development)',
      timeline: [
        {
          moved: -1,
          ringId: 'hold',
          date: new Date('2022-02-01'),
          description: 'Moving over to Scrum for now',
        },
        {
          ringId: 'use',
          date: new Date('2020-01-01'),
          description: 'Kanban is it!',
        }
      ],
    });

    entries.push({
      id: 'scrum',
      key: 'scrum',
      title: 'Scrum',
      description: 'A more formal version of Kanban',
      quadrant: 'processes',
      url: 'https://en.wikipedia.org/wiki/Scrum_(software_development)',
      timeline: [
        {
          ringId: 'use',
          date: new Date('2022-02-01'),
          description: 'All front-end code is built in Angular',
        },
      ],
    });

    entries.push({
      id: 'arch',
      key: 'arch',
      title: 'Architecture Forum',
      description: 'Architecture and tech debt forum',
      quadrant: 'processes',
      url: '#',
      timeline: [
        {
          ringId: 'use',
          date: new Date('2022-05-01'),
          description: '',
        },
      ],
    });

    entries.push({
      id: 'backlogRefinement',
      key: 'backlogRefinement',
      title: 'Backlog Refinement',
      description: '',
      quadrant: 'processes',
      url: '#',
      timeline: [
        {
          ringId: 'use',
          date: new Date('2022-05-01'),
          description: '',
        },
      ],
    });

    entries.push({
      id: 'designReview',
      key: 'designReview',
      title: 'Design reviews',
      description: '',
      quadrant: 'processes',
      url: '#',
      timeline: [
        {
          ringId: 'use',
          date: new Date('2023-09-01'),
          description: '',
        },
        {
          ringId: 'trial',
          date: new Date('2022-10-28'),
          description: '',
        },
      ],
    });

    const data: TechRadarLoaderResponse = {
      quadrants,
      rings,
      entries,
    }
    return data;
  }
}

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  ScmAuth.createDefaultApiFactory(),
  createApiFactory(techRadarApiRef, new TechRadarClient()),
];
