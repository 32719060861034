import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import {
    HomePageSearchBar,
} from '@backstage/plugin-search';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { Content, Page } from '@backstage/core-components';
import { HomePageStarredEntities, HomePageToolkit } from '@backstage/plugin-home';
import InputIcon from '@material-ui/icons/Input';
import GitHubIcon from '@material-ui/icons/GitHub';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import CloudIcon from '@material-ui/icons/Cloud';

const useStyles = makeStyles(theme => ({
  searchBar: {
    display: 'flex',
    maxWidth: '60vw',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    padding: '8px 0',
    borderRadius: '50px',
    margin: 'auto',
  },
}));


export const HomePage = () => {
  const classes = useStyles();
  return (
  <SearchContextProvider>
      <Page themeId="home">
        <Content>
          <Grid container justifyContent="center" spacing={6}>
            <Grid container item xs={12} alignItems="center" direction="row">
              <HomePageSearchBar
                classes={{ root: classes.searchBar }}
                placeholder="Search"
              />
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} md={6}>
                <HomePageStarredEntities />
              </Grid>
              <Grid item xs={12} md={6}>
                <HomePageToolkit
                  tools={[{
                    url: 'https://app.climateview.dev/',
                    label: 'Dev Login',
                    icon: <InputIcon />,
                  },{
                    url: 'https://climateviewdevstorybook.z16.web.core.windows.net',
                    label: 'Storybook',
                    icon: <MenuBookIcon />,
                  },{
                    url: 'https://portal.azure.com',
                    label: 'Azure Portal',
                    icon: <CloudIcon />,
                  },{
                    url: 'https://github.com/ClimateView/',
                    label: 'GitHub',
                    icon: <GitHubIcon />,
                  },{
                    url: 'https://climateview.grafana.net',
                    label: 'Grafana',
                    icon: <InputIcon />,
                  },{
                    url: 'https://api.climateview.net/dashboard',
                    label: 'Lattice',
                    icon: <InputIcon />,
                  }]
                  }
                />
              </Grid>
              {/* <Grid item xs={12} md={6}> */}
                {/* <InfoCard title="Placeholder"> */}
                  {/* placeholder for content */}
                  {/* <div style={{ height: 210 }} /> */}
                {/* </InfoCard> */}
              {/* </Grid> */}
              {/* <Grid item xs={12} md={6}> */}
                {/* <InfoCard title="Placeholder"> */}
                  {/* placeholder for content */}
                  {/* <div style={{ height: 210 }} /> */}
                {/* </InfoCard> */}
              {/* </Grid> */}
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};